<template>
  <FundoValidation :provider_id="29" />
</template>
<script>
import FundoValidation from "@/components/FundoValidation";
export default {
  components: {
    FundoValidation,
  },
};
</script>